.container {
  flex: 1;
}
.content {
  padding-top: 224px;
  width: 400px;
  margin: 0 auto;
}



@media (max-width: 960px) {
  .content {
    padding: 56px 15px 15px;
    margin: 0 auto;
    width: 100%;
  }
}

