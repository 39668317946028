.container {
  display: flex;
  height: 100vh;
}
.left {
  height: 100vh;
  width: 800px;
}
.right {
  height: 100vh;
  width: calc(100vw - 800px);
  overflow: auto;
}


@media (max-width: 960px) {
  .container {
    display: flex;
    height: unset;
    flex-direction: column-reverse;
  }
  .left {
    // display: none;
    height: unset;
    width: 100%;
  }
  .right {
    height: unset;
    width: 100%;
  }
}

@media only screen and (min-width: 961px) and (max-width: 1200px) {
  .left {
    width: 500px;
  }
  .right {
    width: calc(100vw - 500px);
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1439px) {
  .left {
    width: 650px;
  }
  .right {
    width: calc(100vw - 650px);
  }
}
